export const CLIENT_ID = 'edgewater' as const;
export type ClientId = typeof CLIENT_ID;

export type LoginPermissionKey = 'login';
export type AppPermission<T extends string> = `${ClientId}.${T}`;

export const MUI_LICENSE_KEY =
    'c3df9b934f02243513226609aa147d80Tz02NTQ1NyxFPTE3MTQzMjM1NDI3OTUsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y';

export const SUPPORT_PORTAL_LINK = 'https://insight2profit.atlassian.net/servicedesk/customer/portal/25';
